.krovus__hero-split__start {
    width: 100%;
    margin: 2rem 0;
    display: flex;
}

.krovus__hero-split__input {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    padding: 11px 15px;
    height: 48px;
    border-radius: 0;
    color: #455C80;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #D3DDEC;
}

.krovus__hero-split__input:focus {
    border-color: #4346de;
    outline: 0 none;
}

.krovus__hero-split__logo h3 {
    margin-top: 2rem;
}

@media screen and (max-width: 650px) {
    .krovus__hero-split__start{
        width: 100%;
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
    }
}

.shine-button {
  position: relative;
  overflow: hidden;
}

.shine-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(138, 43, 226, 0) 0%,
    rgba(138, 43, 226, 0.4) 50%,
    rgba(138, 43, 226, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(45deg);
  }
}

.list-style-none {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.list-style-none li {
  margin-bottom: 0.5rem;
}

.list-style-none li:last-child {
  margin-bottom: 0;
}

.how-we-work-steps {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.step {
  padding: 1rem;
  border-radius: 4px;
  background-color: rgba(244, 244, 249, 0.1);
}

.step-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.step-text {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

.services-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.service-item {
  padding: 1rem;
  border-radius: 4px;
  background-color: rgba(244, 244, 249, 0.1);
}

.service-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.service-text {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

.scroll-indicator {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.chevron {
  position: relative;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #000000;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 0.7;
    transform: translateY(20px);
  }
  66.6% {
    opacity: 0.7;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale(0.3);
  }
}

